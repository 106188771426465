import { inject } from "mobx-react";
import type { Store } from "@inferno/renderer-shared-core";

import { ResponsiveImage } from "../../../core/components/ResponsiveImage.component";
import { MagicLink } from "../../../core/ui";
import { CoastFeedItemType, getCoastFeedImg } from "../lib/coastFeed";
import { ABV_HEADER_IMAGE, RESPONSIVE_IMAGE, reverseRoute } from "@inferno/renderer-shared-core";

import "./CoastShowPlaceholder.style.scss";

interface CoastShowPlaceholderProps {
  store?: Store;
}

const CoastShowPlaceholder = inject("store")(({ store }: CoastShowPlaceholderProps) => {
  if (!store) {
    return null;
  }

  const { imgSrc, srcset } = getCoastFeedImg(ABV_HEADER_IMAGE, CoastFeedItemType.SHOW, { quality: 80 });
  const artBellVaultURL = reverseRoute(store.site, "art_bell") || "#";

  return (
    <MagicLink to={artBellVaultURL} className="coast-show-placeholder">
      <ResponsiveImage
        alt="Art Bell Vault"
        src={imgSrc}
        srcset={srcset}
        initialWidth={RESPONSIVE_IMAGE.responsiveImageInitialWidth}
        initialHeight={RESPONSIVE_IMAGE.responsiveImageInitialHeight}
      />
    </MagicLink>
  );
});

export default CoastShowPlaceholder;
